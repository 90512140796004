<template>
  <footer
    class="footer px-6 pt-6 md:pt-40"
    :style="{
      backgroundImage: `url(${require('@/assets/homepage/world-map.png')})`,
    }"
  >
    <HomepageHeadingSection :heading="$t('homepage.footer.contacts-heading')" />
    <div class="md:grid md:grid-cols-2 pt-10 md:pt-24 pb-14 footer-container">
      <div class="col-span-1 md:pr-28">
        <FooterContactForm />
      </div>
      <div class="mt-16 md:mt-0 col-span-1 md:flex md:items-end">
        <FooterAddress />
      </div>
    </div>
    <p class="text-xl uppercase pb-14">© 2022 XlinX.EU</p>
  </footer>
</template>

<script>
import HomepageHeadingSection from "@/modules/homepage/components/homepage-heading-section/HomepageHeadingSection.vue";
import FooterContactForm from "@/modules/homepage/footer/components/footer-contact-form/FooterContactForm.vue";
import FooterAddress from "@/modules/homepage/footer/components/footer-address/FooterAddress.vue";

export default {
  components: {
    HomepageHeadingSection,
    FooterContactForm,
    FooterAddress,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #f4f4f4;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .footer {
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>

<template>
  <nav class="header-nav flex flex-col md:flex-row p-5 md:p-0 md:justify-end">
    <div
      class="text-base uppercase my-4 md:my-0 md:ml-10 cursor-pointer"
      v-for="(link, index) in nav"
      :key="index"
      @click="scrollElement(link.link, link.isLink)"
    >
      {{ link.title }}
    </div>
    <div class="text-base uppercase my-4 md:my-0 md:ml-10 cursor-pointer">
      <router-link to="widget">
        {{
          !isToken
            ? $t("heading.main.pages.login.login")
            : $t("link.app.header.admin")
        }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import { ref, inject, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "HeaderNavigation",
  emits: ["closeDrawer"],

  setup(props, { emit }) {
    const t = inject("t");
    const route = useRoute();
    const router = useRouter();
    const isToken = ref(false);

    const nav = ref([
      { title: t("link.homepage.header.home"), link: "home", isLink: false },
      {
        title: t("link.homepage.header.configuration"),
        link: "configuration",
        isLink: true,
      },
      {
        title: t("link.homepage.header.features"),
        link: "homepage-feature-cards",
        isLink: false,
      },
      {
        title: t("link.homepage.header.testimonials"),
        link: "testimonial-container",
        isLink: false,
      },
      {
        title: t("link.homepage.header.pricing"),
        link: "plans-container",
        isLink: false,
      },
      {
        title: t("link.homepage.header.contact"),
        link: "footer",
        isLink: false,
      },
    ]);

    const scrollElement = (link, isLink) => {
      if (isLink) {
        router.push({ name: link });
      } else if (route.name === "configuration") {
        router.push({ name: "home" });
      } else {
        const windowWidth = window.innerWidth;

        if (windowWidth < 768) emit("closeDrawer", true);

        const scrolledElement = document.querySelector(`.${link}`);
        if (scrolledElement) {
          const yOffset = -10;
          const y =
            scrolledElement.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    };

    onMounted(() => {
      const token = localStorage.getItem("token");

      isToken.value = token ? true : false;
    });

    return {
      nav,
      isToken,
      scrollElement,
    };
  },
};
</script>

<style lang="scss" scoped>
.header-nav {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}
</style>

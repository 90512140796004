<template>
  <div>
    <div class="input-form mb-8 relative">
      <input
        type="text"
        placeholder="Name"
        class="w-full h-full py-3 pl-3"
        v-model="form.name"
      />
      <p
        class="text-sm text-red-400 text-left absolute w-full left-0 -bottom-5"
        v-if="errors.name"
      >
        Name {{ $t("validation.modules.widget.validate.required") }}
      </p>
    </div>
    <div class="input-form mb-8 relative">
      <input
        type="text"
        placeholder="Email"
        class="w-full h-full py-3 pl-3"
        v-model="form.email"
      />
      <p
        class="text-sm text-red-400 text-left absolute w-full left-0 -bottom-5"
        v-if="errors.name"
      >
        Email {{ $t("validation.modules.widget.validate.required") }}
      </p>
    </div>
    <div class="input-form mb-8 relative">
      <textarea
        name=""
        id=""
        placeholder="Message"
        class="w-full h-full py-3 pl-3"
        v-model="form.message"
      ></textarea>
      <p
        class="text-sm text-red-400 text-left absolute w-full left-0 -bottom-5"
        v-if="errors.name"
      >
        Message {{ $t("validation.modules.widget.validate.required") }}
      </p>
    </div>
    <button class="button w-full text-white" @click="submitForm">
      {{ $t("homepage.footer.contacts-button") }}
    </button>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import { useStore } from "vuex";
import axios from "@/services/axios";
import useToaster from "@/composables/useToaster";

export default {
  setup() {
    const globalError = ref(false);
    const store = useStore();
    const { trigger } = useToaster();
    const t = inject("t");

    const form = ref({
      name: "",
      email: "",
      message: "",
    });

    const errors = ref({
      name: false,
      email: false,
      message: false,
    });

    const submitForm = () => {
      globalError.value = false;
      for (const key in form.value) {
        if (!form.value[key]) {
          errors.value[key] = true;
          globalError.value = true;
        }
      }

      if (!globalError.value) submit();
    };

    const submit = async () => {
      const formData = { ...form.value };

      try {
        store.commit(`stats/setLoading`, { id: "stats", value: true });

        const response = await axios.post("api/contact", formData);
        store.commit(`stats/setLoading`, { id: "stats", value: true });
        
        if (response.status === 200) {
          trigger({
            message: t("notify.homepage.contact-send"),
            type: "success",
          });
        }
      } catch (e) {
        store.commit(`stats/setLoading`, { id: "stats", value: false });
        trigger({
          message: t("notify.homepage.contact-error"),
          type: "danger",
        });
      }
    };

    return {
      form,
      errors,
      globalError,
      submitForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-form {
  textarea,
  input {
    border: none;
    outline: none;
  }

  textarea {
    min-height: 160px;
  }
}

.button {
  height: 46px;
  background: #f48120;
}

textarea::placeholder,
input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: "Open Sans";
  color: #7d7d7d;
}

input::placeholder {
  color: #7d7d7d;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7d7d7d;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7d7d7d;
}
</style>
<template>
  <div class="pb-4 md:pb-12 text-center md:text-left footer-address">
    <!-- <p>{{ $t("homepage.footer.address-1") }}</p>
    <p>{{ $t("homepage.footer.address-2") }}</p>
    <p>sales@xlinx.eu</p>
    <p>{{ $t("homepage.footer.address-4") }}</p>
    <p>{{ $t("homepage.footer.address-5") }}</p>
    <p>sales@xlinx.eu</p> 
  --></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer-address {
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  color: #7d7d7d;
  font-family: "Open Sans";
}
</style>

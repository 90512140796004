<template>
  <div class="drawer block md:hidden" :class="{ active: isActive }">
    <div
      class="hamburger flex flex-col justify-between absolute py-4 px-5 cursor-pointer text-4xl"
      @click="$emit('close')"
    >
      +
    </div>
    <HeaderNavigation @closeDrawer="$emit('close')" />
  </div>
</template>

<script>
import HeaderNavigation from '@/modules/homepage/components/header-navigation/HeaderNavigation.vue'

export default {
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },

  components: {
    HeaderNavigation
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  /* box-shadow: -8px 0px 14px -6px rgb(0 0 0 / 75%); */
  width: 290px;
  position: fixed;
  top: 0;
  right: 0;
  width: 290px;
  height: 100%;
  z-index: 111;
  background-color: #fff;
  overscroll-behavior: contain;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateX(290px);

  &.active {
    transform: translateX(0);
  }
}

.hamburger {
  top: 5px;
  right: 5px;
  transform: rotate(45deg);
}
</style>

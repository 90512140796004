<template>
  <h3 class="text-3xl md:text-4xl mt-3 md:mt-6 mb-3 font-bold main-heading">
    {{ heading }}
  </h3>
  <div class="divider" :class="{ white: isWhiteBorder }"></div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    isWhiteBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  width: 60px;
  margin: 0 auto 50px;
  border-bottom: 4px solid #17b191;
}

.divider.white {
  border-bottom: 4px solid #fff;
}

.main-heading {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}
</style>
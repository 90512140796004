<template>
  <header class="flex items-center justify-between relative z-10 header">
    <div class="logo-images flex justify-center items-center">
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/homepage/main-logo.png" alt="Logo" />
      </router-link>
    </div>
    <div class="hidden md:block md:w-full">
      <HeaderNavigation />
    </div>
    <div
      class="hamburger flex flex-col md:hidden justify-between py-4 cursor-pointer"
      @click="closeMenu"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
    <Drawer @close="closeMenu" :isActive="activeMenu" />
  </header>
</template>

<script>
import { ref } from 'vue'
import Drawer from '@/modules/homepage/components/drawer/Drawer.vue'
import HeaderNavigation from '@/modules/homepage/components/header-navigation/HeaderNavigation.vue'

export default {
  components: {
    Drawer,
    HeaderNavigation
  },

  setup () {
    const activeMenu = ref(false)

    const closeMenu = () => {
      activeMenu.value = !activeMenu.value
    }

    return {
      closeMenu,
      activeMenu
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 130px;
  padding-left: 7.2vw;
  padding-right: 7.2vw;
}

.header-image {
  max-width: 300px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.hamburger {
  left: -70px;
  top: 25px;

  span {
    display: inline-block;
    width: 36px;
    height: 2px;
    background-color: #7d7d7d;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .header {
    height: 108px;
  }
}
</style>
